import useChainService from 'src/services/chain/useChainService';

const OwnBrandIcon = () => {
  const chain = useChainService();
  const iconUrl = chain.logoLinks.ownBrandGamification;

  return (
    <img src={iconUrl} className='OwnBrandIcon' width={24} height={24} alt='own-gamification' />
  );
};

export default OwnBrandIcon;
