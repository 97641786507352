import cn from 'classnames';
import ProgressBarIcon from './Icons/ProgressBarIcon';
import styles from './ProgressBarLine.styles';

function getPercentage(min: number, max: number, value: number): number {
  if (value <= min) {
    return 0;
  }

  if (value >= max) {
    return 100;
  }

  const range = max - min;
  const normalizedValue = value - min;

  return (normalizedValue / range) * 100;
}

interface ProgressBarLineProps {
  minValue: number;
  maxValue: number;
  value: number;
}

export const ProgressBarLine = (props: ProgressBarLineProps) => {
  const { minValue, maxValue, value } = props;
  const percentage = getPercentage(minValue, maxValue, value);
  const leftPlaceholderIsFilled = value > minValue;
  const rightPlaceholderIsFilled = value > maxValue;
  const cartIsDisplayed = value <= maxValue && value >= minValue;

  return (
    <div className='ProgressBarLine' data-testid='ProgressBarLine'>
      <div
        className={cn('ProgressBarLine__placeholder', {
          ProgressBarLine__placeholder_filled: leftPlaceholderIsFilled,
        })}
      />

      <div className='ProgressBarLine__line'>
        <div
          className='ProgressBarLine__line_filled'
          style={{ right: `${100 - percentage}%` }}
        />
        {cartIsDisplayed && (
          <div
            className='ProgressBarLine__car'
            style={{ left: `${percentage}%` }}
            data-marker="Progress Bar Car Icon"
            data-testid="ProgressBarCarIcon"
            data-value={percentage}
          >
            <ProgressBarIcon/>
          </div>
        )}
      </div>

      <div
        className={cn('ProgressBarLine__placeholder', {
          ProgressBarLine__placeholder_filled: rightPlaceholderIsFilled,
        })}
      />
      <style jsx>{styles}</style>
    </div>
  );
};
