import css from 'styled-jsx/css';

const styles = css`
  .ProgressBarLine {
    display: flex;
    align-items: center;
  }

  .ProgressBarLine__line {
    position: relative;
    width: 100%;
    height: 4px;
    background-color: var(--colors-brandLightGrey1);
  }

  .ProgressBarLine__line_filled {
    position: absolute;
    top: 0;
    left: 0;
    right: 100%;
    height: 100%;
    background-color: var(--uiColors-ProgressBarLine);
  }

  .ProgressBarLine__placeholder {
    flex: 0 0 12px;
    height: 4px;
    background-color: var(--colors-brandLightGrey1);
  }

  .ProgressBarLine__placeholder_filled {
    background-color: var(--uiColors-ProgressBarLine);
  }

  .ProgressBarLine__car {
    position: absolute;
    width: 1px;
    height: 1px;
    top: 0;
    transform: translate(-12px, -10px);
    z-index: 1;
    fill: var(--uiColors-ProgressBarLine);
  }
`;

export default styles;
