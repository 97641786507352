import css from 'styled-jsx/css';

const styles = css`
  .ProgressBarLabel {
    padding: 0 4px;
    min-width: 26px;
    height: 18px;
    text-align: center;
    background-color: var(--colors-brandLightGrey1);
    border-radius: 100px;
    border: 1px solid #fff;
    color: #303030;
    font-size: 10px;
    line-height: 16px;
    transition: color 0.3s, background-color 0.3s;
  }

  .ProgressBarLabel_filled {
    background-color: var(--uiColors-ProgressBarLine);
    color: var(--uiColors-Button-text);
  }
`;

export default styles;
