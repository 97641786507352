import cn from 'classnames';
import { Fragment } from 'react';
import { useAnimatedValue } from 'src/hooks/useAnimatedValue';
import ProgressBarIcon from './Icons/ProgressBarIcon';
import styles from './ProgressBar.styles';
import { ProgressBarLabel } from './ProgressBarLabel';
import { ProgressBarLine } from './ProgressBarLine';

const DURATION = 380;

export interface ProgressBarPoint {
  label: string;
  value: number;
  dataMarker?: string;
}

export interface ProgressBarProps {
  points: ProgressBarPoint[];
  value: number;
}

const ProgressBar = (props: ProgressBarProps) => {
  const { points, value } = props;
  const indexOfLastPoint = points.length - 1;
  const animatedValue = useAnimatedValue(value, DURATION);

  return (
    <div className='ProgressBar' data-marker='Progress_bar_delivery_fee'>
      {points.map((point, indexOfPoint) => {
        const pointIsLast = indexOfPoint === indexOfLastPoint;
        // The СarIcon shows instead of the last label
        // when value has reached the maximum
        const displayCarInsteadLabel = pointIsLast &&
          (animatedValue >= point.value);
        const nextPointValue = points[indexOfPoint + 1]?.value;

        return (
          <Fragment key={point.value}>
            <div className="ProgressBar__carHolder">
              {displayCarInsteadLabel && (
                <div
                  className='ProgressBar__carIcon'
                  data-testid='ProgressBarCarIcon'
                  data-marker='Car'
                >
                  <ProgressBarIcon/>
                </div>
              )}

              <div
                className={cn('ProgressBar__label', {
                  ProgressBar__label_hidden: displayCarInsteadLabel,
                })}
              >
                <ProgressBarLabel
                  isFilled={point.value <= animatedValue}
                  text={point.label}
                  dataMarker={point.dataMarker}
                />
              </div>
            </div>

            {!pointIsLast && (
              <div className='ProgressBar__line'>
                <ProgressBarLine
                  value={animatedValue}
                  minValue={point.value}
                  maxValue={nextPointValue - 1}
                />
              </div>
            )}
          </Fragment>
        );
      })}
      <style jsx>{styles}</style>
    </div>
  );
};

export default ProgressBar;
