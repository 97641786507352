import css from 'styled-jsx/css';

const styles = css`
  .ProgressBar {
    display: flex;
    width: 100%;
    align-items: center;
    margin: -7px 0;
  }

  .ProgressBar__label {
    flex: 0 0 auto;
  }

  .ProgressBar__label_hidden {
    visibility: hidden;
    transition: none;
  }

  .ProgressBar__line {
    flex: 1 0 auto;
  }

  .ProgressBar__carHolder {
    position: relative;
  }

  .ProgressBar__carIcon {
    position: absolute;
    left: 0;
    width: 26px;
    height: 18px;
    margin-top: -2px;
    fill: var(--uiColors-Button-background);
    line-height: 0;
  }
`;

export default styles;
