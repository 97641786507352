import { useEffect, useRef, useState } from 'react';
import { easeInOutTransition, TimingFn } from './timing-functions';

export const useAnimatedValue = (
  value: number,
  duration = 300,
  timingFn: TimingFn = easeInOutTransition,
) => {
  const [animatedValue, setAnimatedValue] = useState(value);
  const lastAnimatedValueRef = useRef(value);
  const curValueRef = useRef(animatedValue);
  curValueRef.current = animatedValue;

  useEffect(() => {
    let startTimestamp;
    let animationFrame;
    const startValue = lastAnimatedValueRef.current;
    const finishValue = value;

    const animate = (timestamp) => {
      const progress = timestamp - startTimestamp;
      const percentage = Math.min(progress / duration, 1) * 100;
      const currentValue = timingFn(startValue, finishValue, percentage);

      setAnimatedValue(currentValue);
      lastAnimatedValueRef.current = currentValue;

      if (progress < duration) {
        animationFrame = requestAnimationFrame(animate);
      } else {
        /* timing function can return not exactly value,
        we must set finish value after animation during */
        setAnimatedValue(finishValue);
      }
    };

    if (startValue !== finishValue) {
      startTimestamp = performance.now();
      animationFrame = requestAnimationFrame(animate);
    }

    return () => {
      cancelAnimationFrame(animationFrame);
    };
  }, [value, duration, timingFn]);

  return animatedValue;
};
