import { selectStairsDiscount } from "src/redux/cart/general/cartSelectors";
import { useAppSelector } from "src/redux/hooks";
import CarIcon from "./CarIcon";
import OwnBrandIcon from "./OwnBrandIcon";

type GamificationIconVariant = 'default' | 'ownBrand';

const ProgressBarIcon = () => {
  const stairsDiscount = useAppSelector(selectStairsDiscount);
  const variant: GamificationIconVariant = stairsDiscount?.retailer_own_brand ? 'ownBrand' : 'default';

  if (variant === 'ownBrand') {
    return <OwnBrandIcon />;
  }

  return <CarIcon />;
};

export default ProgressBarIcon;
