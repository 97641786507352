import cn from 'classnames';
import styles from './ProgressBarLabel.styles';

interface ProgressBarLabelProps {
  text: string;
  dataMarker?: string;
  isFilled: boolean;
}

export const ProgressBarLabel = (props: ProgressBarLabelProps) => {
  const { text, isFilled, dataMarker } = props;

  return (
    <div
      className={cn('ProgressBarLabel', {
        ProgressBarLabel_filled: isFilled,
      })}
      data-testid='ProgressBarLabel'
      data-status={isFilled ? 'filled' : 'unfilled'}
      data-marker={dataMarker}
    >
      {text}
      <style jsx>{styles}</style>
    </div>
  );
};
