export interface TimingFn {
  (min: number, max: number, percentage: number): number;
}

export const linearTransition: TimingFn = (min, max, percentage) => {
  const range = max - min;
  return min + (range * percentage) / 100;
};

export const easeInTransition: TimingFn = (min, max, percentage) => {
  const range = max - min;
  return min + Math.pow(percentage / 100, 2) * range;
};

export const easeOutTransition: TimingFn = (min, max, percentage) => {
  const range = max - min;
  return min + (1 - Math.pow(1 - percentage / 100, 2)) * range;
};

export const easeInOutTransition: TimingFn = (min, max, percentage) => {
  const range = max - min;
  const t = percentage / 100;
  return min + (-Math.cos(t * Math.PI) / 2 + 0.5) * range;
};

export const exponentialTransition: TimingFn = (min, max, percentage) => {
  const range = max - min;
  return min + Math.pow(2, 10 * (percentage / 100 - 1)) * range;
};
